import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { EventsService } from '../../../../services/events/events.service';
import { environment } from '../../../../../environments/environment';
import { LoadingIndicatorService } from '../../../../services/loading-indicator/loading-indicator.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tripmakery-offer-change-requests-edit-modal',
  templateUrl: './modal-offer-change-requests-edit.component.html',
  styleUrls: ['./modal-offer-change-requests-edit.component.css']
})
export class ModalOfferChangeRequestsEditComponent implements OnInit {
  public offerChangeRequestId: string;
  public offerChangeRequestData: any;
  public form: FormGroup;
  public environment_;

  @ViewChild('childModal') public childModal: ModalDirective;

  constructor(private router: Router,
              private http: HttpClient,
              private loadingIndicatorService: LoadingIndicatorService,
              private eventsService: EventsService) {
    this.form = new FormGroup({
      'state': new FormControl({value: '', disabled: false}),
      'messageOverride': new FormControl({value: '', disabled: false}),
    });
    this.environment_ = environment;
  }

  ngOnInit() {
    this.eventsService.on('requestShowModalOfferChangeRequestEdit', (offerChangeRequestId) => {
      this.offerChangeRequestId = offerChangeRequestId;
      this.loadChangeRequestDetails();
      this.showChildModal();
    });
  }

  private async loadChangeRequestDetails() {
    this.loadingIndicatorService.showLoadingBar();
    this.http.get(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests/' + this.offerChangeRequestId)
      .subscribe(
        async (response: any) => {

          /* */
          response.frontend = {
            urlUserRequest: await this.createUrlToUserAreaForRequest(response.requestId, response.userId, response.isB2bRequest),
            urlUserOffer: await this.createUrlToUserAreaForOffer(response.offerId, response.userId, response.isB2bRequest)
          };

          this.offerChangeRequestData = response;


          /* */
          this.form.get('state').setValue(response.state);
          this.form.get('messageOverride').setValue(response.messageOverride);
        },
        (error) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.loadingIndicatorService.hideLoadingBar();
        }
      );
  }

  public showChildModal(): void {
    this.childModal.show();
  }

  public hideChildModal(): void {
    this.childModal.hide();
  }

  public handleClick() {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests/' + this.offerChangeRequestId, {
      state: (this.offerChangeRequestData.state === 'NEW') ? 'IN_PROGRESS' : this.createNullIfEmptyString(this.form.get('state').value),
      messageOverride: this.createNullIfEmptyString(this.form.get('messageOverride').value),
      notifyHousing: true,
    })
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('refreshOfferChangeRequestsList');
        },
        (error) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.hideChildModal();
        }
      );
  }

  public handleFastStateSwitchWithoutEmail(state: string) {
    this.http.patch(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests/' + this.offerChangeRequestId + '/fast-state-switch', {
      state: state,
    })
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('refreshOfferChangeRequestsList');
        },
        (error) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.hideChildModal();
        }
      );
  }

  public handleMoveOfferChangeRequestToMessage() {
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests/' + this.offerChangeRequestId + '/move-to-message', {})
      .subscribe(
        async (response: any) => {
          this.hideChildModal();
          await this.router.navigate(['offer', 'messages', response.offerId, response.offerMessage._id]);
          window.location.reload();
        },
        (error) => {
          console.log(error);
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.hideChildModal();
        }
      );
  }

  public handleResendPartnerMail() {
    this.http.post(environment.urls.service + '/api/v1/authenticated/admin/offer/change-requests/' + this.offerChangeRequestId + '/emails/partner/creation-notification', {})
      .subscribe(
        (response: any) => {
          this.eventsService.broadcast('refreshOfferChangeRequestsList');
        },
        (error) => {
          this.eventsService.broadcast('requestShowErrorMessage', JSON.stringify(error));
        },
        () => {
          this.offerChangeRequestData.emailStatus.housing.creationNotification = {
            sent: false,
            sentAt: null,
          };
          // this.hideChildModal();
        }
      );
  }

  private createNullIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return null;
    }
    return s;
  }

  private createFalseIfEmptyString(s: string) {
    if (s != null && typeof s === 'string' && s.trim() === '') {
      return false;
    }
    return s;
  }

  public async createUrlToUserAreaForRequest(requestId: string,
                                             userId: string,
                                             b2bRequest: boolean) {
    if (b2bRequest === true) {
      const token = await this.handleGetLoginToken(userId);
      return environment.urls.pro + '/dashboard/requests/' + requestId + '?token=' + token;
    }
    return environment.urls.user + '/de/request/' + requestId + '/dashboard';
  }

  public async createUrlToUserAreaForOffer(offerId: string,
                                           userId: string,
                                           b2bRequest: boolean) {
    if (b2bRequest === true) {
      const token = await this.handleGetLoginToken(userId);
      return environment.urls.pro + '/offer/' + offerId + '?token=' + token;
    }
    return environment.urls.user + '/de/offer/' + offerId;
  }

  private async handleGetLoginToken(userId: string) {
    const data = await this.http.post(environment.urls.service + '/api/v1/authenticated/admin/user/' + userId + '/login-token', {}).toPromise();
    return (data as any).loginToken.token;
  }

}
